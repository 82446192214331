import { Avatar, Menu, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAuth, useReduxStore } from '@/hooks';
import { useAppDispatch } from '@/store/hooks';
import { userActions } from '@/store/user';
import Utils from '@/utils';

export const UserLogin = () => {
  const { t } = useTranslation('layout');

  const { changeStoreConfig } = useReduxStore();

  const auth = useAuth();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const handleNavigateOrgPage = () => {
    navigate('/organization');
    dispatch(userActions.setDefaultOrganization(undefined));
  };

  const handleSignout = () => {
    auth.signout(() => {
      changeStoreConfig({});
    });
  };

  return (
    auth.user && (
      <>
        <p style={{ color: 'white', marginRight: 10 }}>{`${auth.user.Firstname}`.trim()}</p>
        <Popover
          content={
            <Menu style={{ borderInlineEnd: 0 }}>
              <Menu.Item onClick={handleNavigateOrgPage}>{t('Chọn organization')}</Menu.Item>
              <Menu.Item onClick={handleSignout}>{t('Sign out')}</Menu.Item>
            </Menu>
          }
          title={`${auth.user.Lastname} ${auth.user.Firstname}`.trim()}
          arrow={false}
        >
          <Avatar size={30} style={{ backgroundColor: Utils.stringToColour(auth.user.Id), cursor: 'pointer' }}>
            {auth.user.Firstname.charAt(0)}
          </Avatar>
        </Popover>
      </>
    )
  );
};
