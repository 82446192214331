import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();

export interface EmployeesPagingResponse extends PagingResponse {
  results: EmployeeResponse[];
}

export interface EmployeeResponse {
  id: number;
  userId: string;
  companyId: number;
  employeeCode: string;
  contactDetail: ContactResponse;
  dateOfBirth: string;
  startDate: string;
  endDate: string;
  gender: string;
  firstName: string;
  middleName: string;
  lastName: string;
  homeTown: string;
  identity: string;
  maritalStatus: string;
  nationality: string;
  picture: string;
  status: number;
  mood: string;
}

export interface ContactResponse {
  employeeId: number;
  addressStreet1: string;
  addressStreet2: string;
  city: string;
  country: string;
  homePhone: string;
  mobile: string;
  otherEmail: string;
  workEmail: string;
  workPhone: string;
  zipCode: string;
}

export interface CreateEmployeePayload {
  companyId: number;
  companyGuid: string;
  employeeCode: string;
  dateOfBirth: string;
  startDate: string;
  endDate: string;
  gender: string;
  firstName: string;
  middleName: string;
  lastName: string;
  homeTown: string;
  identity: string;
  maritalStatus: string;
  nationality: string;
  picture: string;
  status: number;
  mood: string;
  contactDetail: {
    addressStreet1: string;
    addressStreet2: string;
    city: string;
    country: string;
    homePhone: string;
    mobile: string;
    otherEmail: string;
    workEmail: string;
    workPhone: string;
    zipCode: string;
  };
}

export interface UpdateEmployeePayload extends CreateEmployeePayload {
  id: number;
}

class EmployeeController {
  public Get = {
    getAllEmployees: (options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee`, options);
    },
    getEmployees: (companyId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee/company/${companyId}`, options);
    },
    getEmployeeDetails: (employeeId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Employee/${employeeId}`, options);
    }
  };

  public Post = {
    createEmployee: (input: CreateEmployeePayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Employee/detail`, input, options);
    },
  };

  public Put = {
    updateEmployee: (employeeId: number, input: any, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Employee/${employeeId}/detail`, input, options);
    },
  };

  public delete = {
    removeEmployee: (EmployeeId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Employee/${EmployeeId}`, options);
    },
  };
}

export const EmployeeService = new EmployeeController();
