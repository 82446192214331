import React from 'react';

import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

const ProjectLayout = () => {
  const location = useLocation();
  const selectedProject = useAppSelector(getSelectedProject());

  if (!selectedProject) {
    return <Navigate to="/projects" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default ProjectLayout;
