import { catchError, concat, filter, switchMap, withLatestFrom } from 'rxjs';

import { userActions } from '.';
import { UserService } from '@/services/UserService';
import { startLoading, stopLoading } from '@/store/loading';
import { RootEpic } from '@/store/types';
import Utils from '@/utils';

const getUserPreferences$: RootEpic = action$ => {
  return action$.pipe(
    filter(userActions.getUserPreferences.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'GetUserPreferences' })],
        UserService.Get.getUserPreferences().pipe(
          switchMap(preferences => {
            return [userActions.setUserPreferences(preferences)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [userActions.setFetchingPreferences(false), stopLoading({ key: 'GetUserPreferences' })],
      );
    }),
  );
};

const getCurrentUser$: RootEpic = (action$, state$) => {
  return action$.pipe(
    filter(userActions.getCurrentUser.match),
    withLatestFrom(state$),
    switchMap(([_, state]) => {
      const { auth } = state.app;
      if (!auth) {
        return [];
      }
      const { Email } = auth.user;
      return concat(
        [startLoading({ key: 'GetMe' })],
        UserService.Get.getUserByEmail(Email).pipe(
          switchMap(me => {
            return [userActions.setMe(me)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'GetMe' })],
      );
    }),
  );
};

const getUserOrganizations$: RootEpic = action$ => {
  return action$.pipe(
    filter(userActions.getOrganizations.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'GetUserOrganizations' })],
        UserService.Get.getOrganizations().pipe(
          switchMap(organizations => {
            return [userActions.setOrganizations(organizations)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'GetUserOrganizations' })],
      );
    }),
  );
};

const getCurrentConfig$: RootEpic = action$ => {
  return action$.pipe(
    filter(userActions.getCurrentConfigRequest.match),
    switchMap(() => {
      return concat(
        [startLoading({ key: 'getCurrentConfig' })],
        UserService.Get.getCurrentConfig().pipe(
          switchMap(organizations => {
            return [userActions.setCurrentConfig(organizations)];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'getCurrentConfig' })],
      );
    }),
  );
};

const createUserPreferences$: RootEpic = action$ => {
  return action$.pipe(
    filter(userActions.createUserPreferencesRequest.match),
    switchMap(action => {
      const data = action.payload;
      return concat(
        [startLoading({ key: 'createUserPreferences' })],
        UserService.Post.createUserPreferences(data).pipe(
          switchMap(result => {
            // Utils.successNotification(result);
            return [];
          }),
          catchError(error => {
            Utils.errorHandling(error);
            return [];
          }),
        ),
        [stopLoading({ key: 'createUserPreferences' })],
      );
    }),
  );
};

export const userEpics = [
  getUserPreferences$,
  getCurrentUser$,
  getUserOrganizations$,
  getCurrentConfig$,
  createUserPreferences$,
];
