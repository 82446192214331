import { useEffect } from 'react';

import { Row, Typography } from 'antd';

import styles from './CompanyEmployee.module.less';
import { CompanyEmployeeHeader, CompanyEmployeeTable, CreateUpdateEmployeeModal } from './components';
import { CreateUpdateEmployeeModalName } from '@/common/define';
import { getActiveMenu, getCurrentCompany } from '@/store/app';
import { employeeActions, getEmployeeQueryParams } from '@/store/employee';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getModalVisible } from '@/store/modal';

export const CompanyEmployee = () => {
  const dispatch = useAppDispatch();
  const activeMenu = useAppSelector(getActiveMenu());
  const isOpenCreateUpdateEmployeeModal = useAppSelector(getModalVisible(CreateUpdateEmployeeModalName));
  const company = useAppSelector(getCurrentCompany());
  const params = useAppSelector(getEmployeeQueryParams());

  useEffect(() => {
    dispatch(
      employeeActions.getEmployeesRequest({ companyId: company.id, params: { ...params, page: 1, search: undefined } }),
    );
    // eslint-disable-next-line
  }, [company]);

  return (
    <div className={styles.mainContainer}>
      <Row align="stretch" style={{ backgroundColor: 'white', padding: 10 }}>
        <Typography.Title style={{ flex: 1, marginTop: 5 }} level={4}>
          {activeMenu?.label}
        </Typography.Title>
      </Row>
      <CompanyEmployeeHeader />
      <CompanyEmployeeTable />
      {isOpenCreateUpdateEmployeeModal && <CreateUpdateEmployeeModal />}
    </div>
  );
};
