import { createSlice } from "@reduxjs/toolkit";

import { UserPreferencesReponse } from "@/services/UserService";

interface UserState {
  me?: any;
  preferences?: UserPreferencesReponse;
  organizations: any[];
  defaultOragization?: any;
  fetchingPreferences: boolean;
  currentConfig?: any;
}

const initialState: UserState = {
  fetchingPreferences: true,
  organizations: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUserPreferences: (state) => {
      state.fetchingPreferences = true;
    },
    setUserPreferences: (state, action) => {
      state.preferences = action.payload;
      state.fetchingPreferences = false;
    },
    setFetchingPreferences: (state, action) => {
      state.fetchingPreferences = action.payload;
    },
    getCurrentUser: (state) => {},
    setMe: (state, action) => {
      state.me = action.payload;
    },
    getOrganizations: (state) => {},
    setOrganizations: (state, action) => {
      state.organizations = action.payload;
    },
    setDefaultOrganization: (state, action) => {
      state.defaultOragization = action.payload;
    },
    getCurrentConfigRequest: (state) => {},
    setCurrentConfig: (state, action) => {
      state.currentConfig = action.payload;
    },
    createUserPreferencesRequest: (state, action) => {},
  }
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;