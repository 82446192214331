import React, { useEffect, useState } from 'react';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Form, Input, Row, Select, Typography, Upload, UploadProps, notification } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import './GeneralInfo.less';
import { DateEmptyString, FormatDateAPI, SavingProject, formatDateDisplay, largePagingParams } from '@/common/define';
import { getEnvVars } from '@/environment';
import { getAuthenticated, getCurrentCompany } from '@/store/app';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getLoading } from '@/store/loading';
import { getProjectStatusList, getSelectedProject, projectActions } from '@/store/project';

const { apiUrl } = getEnvVars();

export const GeneralInformation = () => {
  const { t } = useTranslation(['projects']);
  const dispatch = useAppDispatch();
  const selectedProject = useAppSelector(getSelectedProject());
  const statusList = useAppSelector(getProjectStatusList());
  const [photoUrl, setPhotoUrl] = useState<string>();
  const isSaving = useAppSelector(getLoading(SavingProject));
  const [uploading, setUploading] = useState(false);
  const auth = useAppSelector(getAuthenticated());
  const company = useAppSelector(getCurrentCompany());

  useEffect(() => {
    dispatch(
      projectActions.getStatusListRequest({
        ...largePagingParams,
        type: 0,
      }),
    );
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setPhotoUrl(selectedProject?.avatar);
  }, [selectedProject]);

  const statusOptions: any[] = statusList.map(x => ({ value: x.id, label: x.name }));

  const uploadButton = (
    <button style={{ border: 0, background: 'none' }} type="button">
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>{t('ProjectPhoto')}</div>
    </button>
  );

  const handleChangePhoto: UploadProps['onChange'] = info => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    }
    if (info.file.status === 'done') {
      const url = info.file.response;
      setUploading(false);
      setPhotoUrl(url);
      return;
    }
    if (info.file.status === 'error') {
      setUploading(false);
      notification.error({
        message: t('Notification'),
        description: t('errorUploadPhoto'),
      });
    }
  };

  const props: UploadProps = {
    name: 'File',
    accept: 'image/*',
    action: `${apiUrl}/Project_Employee/project/uploadImage`,
    headers: {
      Authorization: auth.token ? `Bearer ${auth.token}` : '',
    },
    onChange: handleChangePhoto,
    showUploadList: false,
  };

  const handleSaveProject = (value: any) => {
    const inputData = {
      ...selectedProject,
      ...value,
      avatar: photoUrl,
      startDate: value.startDate ? dayjs(value.startDate).format(FormatDateAPI) : undefined,
    };
    if (selectedProject) {
      dispatch(
        projectActions.updateProjectRequest({ companyId: company.id, projectId: selectedProject.id, data: inputData }),
      );
      return;
    }
    // todo: thêm mới dự án, đẩy dữ liệu vào store cho đến bước cuối cùng thì gọi API để lưu dữ liệu
  };

  return (
    <Form
      initialValues={{
        ...selectedProject,
        startDate:
          selectedProject?.startDate && selectedProject?.startDate !== DateEmptyString
            ? dayjs(selectedProject?.startDate)
            : null,
      }}
      layout="vertical"
      onFinish={handleSaveProject}
    >
      <Row align="stretch" gutter={[16, 10]}>
        {selectedProject && (
          <Col span={24}>
            <Row justify="end">
              <Button type="primary" htmlType="submit" loading={isSaving}>
                {t('Save')}
              </Button>
            </Row>
          </Col>
        )}
        <Col span={24} xl={12}>
          <div style={{ backgroundColor: 'white', borderRadius: 6, padding: 20, marginBottom: 10 }}>
            <Row style={{ marginBottom: 20 }}>
              <Typography.Title style={{ margin: 0 }} level={5}>
                {t('createProject.projectInfo.title')}
              </Typography.Title>
            </Row>
            <Form.Item
              name={'name'}
              label={t('createProject.projectInfo.projectName')}
              rules={[{ required: true, message: t('createProject.projectInfo.requireProjectName') }]}
            >
              <Input placeholder={t('createProject.projectInfo.projectNamePlaceholder')} />
            </Form.Item>
            <Form.Item
              name={'startDate'}
              label={t('createProject.projectInfo.projectStartDate')}
              rules={[{ required: true, message: t('createProject.projectInfo.requireProjectStartDate') }]}
            >
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
            {/* <Form.Item style={{ marginBottom: 0 }}>
              <Form.Item
                label={t('City')}
                name="city"
                style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginRight: 5 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('District')}
                name="district"
                style={{ display: 'inline-block', width: 'calc(50% - 5px)', marginLeft: 5 }}
              >
                <Input />
              </Form.Item>
            </Form.Item> */}
            <Form.Item
              name={'address'}
              label={t('createProject.projectInfo.address')}
              rules={[{ required: true, message: t('createProject.projectInfo.requireAddress') }]}
            >
              <Input />
            </Form.Item>
            <Form.Item name={'description'} label={t('createProject.projectInfo.description')}>
              <Input.TextArea rows={3} />
            </Form.Item>
            <Form.Item name={'status'} label={t('Status')} rules={[{ required: true, message: t('requiredStatus') }]}>
              <Select options={statusOptions} />
            </Form.Item>
            <Form.Item>
              <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader project-photo"
                showUploadList={false}
                {...props}
              >
                {photoUrl ? (
                  <img
                    src={`${apiUrl}/Projects${photoUrl}`}
                    alt="avatar"
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </div>
        </Col>
        <Col span={24} xl={12}>
          <div style={{ backgroundColor: 'white', borderRadius: 6, padding: 20, marginBottom: 10 }}>
            <Row style={{ marginBottom: 20 }}>
              <Typography.Title style={{ margin: 0 }} level={5}>
                {t('createProject.investorInfo.title')}
              </Typography.Title>
            </Row>
            <Form.Item
              name={'ownerName'}
              label={t('createProject.investorInfo.investorName')}
              rules={[{ required: true, message: t('createProject.investorInfo.requireInvestorName') }]}
            >
              <Input placeholder={t('createProject.investorInfo.investorNamePlaceholder')} />
            </Form.Item>
            <Form.Item
              name={'ownerPhone'}
              label={t('createProject.investorInfo.investorPhone')}
              rules={[{ message: t('createProject.investorInfo.requireInvestorPhone') }]}
            >
              <Input placeholder={t('createProject.investorInfo.investorPhonePlaceholder')} />
            </Form.Item>
            <Form.Item
              name={'ownerEmail'}
              label={t('createProject.investorInfo.investorEmail')}
              rules={[
                { message: t('createProject.investorInfo.requireInvestorEmail') },
                {
                  type: 'email',
                  message: t('createProject.investorInfo.emailNotValid'),
                },
              ]}
            >
              <Input placeholder={t('createProject.investorInfo.investorEmailPlaceholder')} />
            </Form.Item>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
