import React, { useEffect } from 'react';

import { UserAddOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Avatar, Button, Empty, Modal, PaginationProps, Space, Table, TableProps, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { BidHeader } from './BidHeader';
import { CreateUpdateIssue } from './CreateUpdateIssue';
import { colors } from '@/common/colors';
import {
  CreateUpdateIssueModalName,
  GettingIssueList,
  RemovingIssue,
  defaultPagingParams,
  formatDateDisplay,
  largePagingParams,
} from '@/common/define';
import { useWindowSize } from '@/hooks';
import { IssuesResponse } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueQueryParams, getIssueStatusList, getIssues, getIssuesView, issueActions } from '@/store/issue';
import { getLoading } from '@/store/loading';
import { getModalVisible, showModal } from '@/store/modal';
import { getSelectedProject, projectActions } from '@/store/project';
import Utils from '@/utils';

export const Bidding = () => {
  const { t } = useTranslation('bidding');

  const windowSize = useWindowSize();

  const dispatch = useAppDispatch();

  const selectedProject = useAppSelector(getSelectedProject());
  const view = useAppSelector(getIssuesView());
  const issues = useAppSelector(getIssues());
  const issueModal = useAppSelector(getModalVisible(CreateUpdateIssueModalName));
  const params = useAppSelector(getIssueQueryParams());
  const isLoading = useAppSelector(getLoading(GettingIssueList));
  const isRemoving = useAppSelector(getLoading(RemovingIssue));
  const issueStatusList = useAppSelector(getIssueStatusList());

  useEffect(() => {
    if (!selectedProject) {
      dispatch(issueActions.setIssues(undefined));
      dispatch(projectActions.setProjectMembers(undefined));
      return;
    }
    dispatch(
      issueActions.getIssuesRequest({
        projectId: selectedProject.id,
        params: { ...params, page: 1, search: undefined },
      }),
    );
    dispatch(
      issueActions.getStatusListRequest({
        projectId: selectedProject.id,
        params: { ...largePagingParams, type: 0 },
      }),
    );
    dispatch(
      issueActions.getProgressListRequest({
        projectId: selectedProject.id,
        params: { ...largePagingParams, type: 1 },
      }),
    );
    dispatch(
      projectActions.getProjectMembersRequest({
        projectId: selectedProject.id,
        queryParams: { ...largePagingParams },
      }),
    );
    // eslint-disable-next-line
  }, [selectedProject]);

  const issuesColumns: any = [
    {
      title: t('ID'),
      dataIndex: 'id',
      key: 'id',
      width: 150,
    },
    {
      title: t('Subject'),
      dataIndex: 'subject',
      key: 'subject',
      width: 200,
    },
    {
      title: t('Start date'),
      dataIndex: 'startDate',
      key: 'startDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    {
      title: t('End date'),
      dataIndex: 'dueDate',
      key: 'dueDate',
      width: 130,
      align: 'center',
      render: (value: string) => (value ? dayjs(value).format(formatDateDisplay) : ''),
    },
    {
      title: t('Assignee'),
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      width: 150,
      align: 'center',
      render: (value: any, record: any) => {
        if (value) {
          const assigneeName = value.fullname;
          return (
            <Tooltip title={assigneeName}>
              <Avatar
                size="small"
                onClick={() => console.log('')}
                style={{ backgroundColor: Utils.stringToColour(assigneeName), cursor: 'pointer' }}
              >
                {assigneeName.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        }
        return (
          <Avatar
            icon={<UserAddOutlined />}
            size="small"
            onClick={() => console.log('')}
            style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
          />
        );
      },
    },
    {
      title: t('Supervisor'),
      dataIndex: 'issueContacts',
      key: 'issueContacts',
      width: 150,
      align: 'center',
      render: (value: any[], record: any) => {
        if (value.length > 0) {
          const supervisorName = value[0].contact.fullname;
          return (
            <Tooltip title={supervisorName}>
              <Avatar
                size="small"
                onClick={() => console.log('')}
                style={{ backgroundColor: Utils.stringToColour(supervisorName), cursor: 'pointer' }}
              >
                {supervisorName.charAt(0)}
              </Avatar>
            </Tooltip>
          );
        }
        return (
          <Avatar
            icon={<UserAddOutlined />}
            size="small"
            onClick={() => console.log('')}
            style={{ backgroundColor: '#87d068', cursor: 'pointer' }}
          />
        );
      },
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      width: 180,
      render: (value: number, record: any) => {
        const selectedStatus = issueStatusList.find(status => status.id === value);
        return <>{selectedStatus?.name}</>;
      },
    },
    {
      title: t('Progress'),
      dataIndex: 'progress',
      key: 'progress',
      width: 180,
      render: (value: number, record: any) => {
        return <>{`${value}%`}</>;
      },
    },
    {
      title: '',
      key: 'operation',
      fixed: 'right',
      width: 70,
      align: 'center',
      render: (_: any, record: any) => {
        return (
          <Space>
            <Tooltip title={t('Edit')}>
              <Button
                icon={<EditOutlined style={{ color: colors.primary }} />}
                type="text"
                size="small"
                onClick={() => editIssue(record)}
              />
            </Tooltip>
            <Tooltip title={t('Remove')}>
              <Button
                icon={<DeleteOutlined />}
                danger
                size="small"
                type="text"
                onClick={() => confirmRemoveIssue(record)}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const editIssue = (issue: any) => {
    dispatch(issueActions.setSelectedIssue(issue));
    dispatch(showModal({ key: CreateUpdateIssueModalName }));
  };

  const confirmRemoveIssue = (issue: IssuesResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('Confirm remove', {
              name: `<strong>"${issue.id}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveIssue(issue.id);
        close();
      },
    });
  };

  const handleRemoveIssue = (issueId: number) => {
    if(selectedProject) {
      dispatch(issueActions.removeIssueRequest({ issueId, projectId: selectedProject.id }));
    }
  };

  const handleIssueTableChange: TableProps<any>['onChange'] = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const search = { ...params, page: current, pageSize };
    if (selectedProject) {
      dispatch(issueActions.getIssuesRequest({ projectId: selectedProject.id, params: search }));
    }
  };

  const showTotal: PaginationProps['showTotal'] = (total, range) =>
    t('Paging total', { range1: range[0], range2: range[1], total });

  return (
    <>
      {issueModal && <CreateUpdateIssue />}
      <BidHeader />
      <div>
        {issues && issues.results && issues.results.length === 0 && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Empty
              description={
                <>
                  <Typography.Title level={4}>{t('No data found based on filtering criteria')}</Typography.Title>
                  <Typography.Text>{t('Try reselecting the filtering criteria to find your data')}</Typography.Text>
                </>
              }
            />
          </div>
        )}
        {view === 'List' && issues && issues.results && issues.results.length > 0 && (
          <div style={{ padding: 10 }}>
            <Table
              rowKey={record => record.id}
              dataSource={issues?.results}
              columns={issuesColumns}
              style={{ width: '100%' }}
              size="small"
              scroll={{ x: 1000, y: windowSize[1] - 310 }}
              pagination={{
                current: params?.page || defaultPagingParams.page,
                pageSize: params?.pageSize || defaultPagingParams.pageSize,
                total: issues?.queryCount || 0,
                responsive: true,
                showTotal,
                showSizeChanger: true,
              }}
              loading={isLoading || isRemoving}
              onChange={handleIssueTableChange}
            />
          </div>
        )}
        {view === 'Gantt' && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: 'calc(100vh - 150px)',
              backgroundColor: 'white',
              margin: 10,
            }}
          >
            <Typography.Title level={2}>Tính năng đang phát triển</Typography.Title>
          </div>
        )}
      </div>
    </>
  );
};
