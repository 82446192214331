import HttpClient from './HttpClient';
import { RequestOptions } from './types';
import { PagingResponse } from '@/common/define';
import { getEnvVars } from '@/environment';

const { apiUrl } = getEnvVars();
export interface IssueTagListPagingResponse extends PagingResponse {
  results: IssueTagResponse[];
}

export interface IssueTagResponse {
  id: number;
  name: string;
  code: string;
  description: string;
  status: number;
  order: number;
  type: number;
  companyId: number;
}
export interface AttributeDTO {
  attributeId: number;
  issuesId: number;
  value: string;
  type: number;
}
export interface ContactDTO {
  id: number;
  issueId: number;
  fullname: string;
  phone: string;
  email: string;
  userId: string;
  employeeId: number;
  employeeCode: number;
}
export interface IssueContactDTO {
  issueId: number;
  contactId: number;
  titleType: number;
  contact: ContactDTO;
}
export interface IssuesPagingResponse extends PagingResponse {
  results: IssuesResponse[];
}
export interface IssuesResponse {
  id: number;
  parentId: number;
  projectId: number;
  categoryId: number;
  createdOn: string;
  subject: string;
  description: string;
  supervisor: {
    id: number;
    fullname: string;
    phone: string;
  };
  priority: number;
  stars: number;
  status: number;
  type: number;
  estimatedTime: number;
  progress: number;
  startDate: string;
  dueDate: string;
  startTime: string;
  attachmentLinks: [
    {
      id: number;
      issueId: number;
      issue: {
        id: number;
        parentId: number;
        projectId: number;
        categoryId: number;
        createdOn: string;
        subject: string;
        description: string;
        notes: string;
        createdById: number;
        createdBy: {
          id: number;
          issueId: number;
          issue: string;
          fullname: string;
          phone: string;
          userId: string;
        };
        supervisorId: number;
        supervisor: {
          id: number;
          issueId: number;
          issue: string;
          fullname: string;
          phone: string;
          userId: string;
        };
        priority: number;
        stars: number;
        status: number;
        type: number;
        estimatedTime: number;
        startDate: string;
        dueDate: string;
        startTime: string;
        assignedTo: [
          {
            id: number;
            issueId: number;
            issue: string;
            fullname: string;
            phone: string;
            userId: string;
          },
        ];
        attachmentLinks: string[];
        progress: number;
        issue_CheckItems: [
          {
            id: number;
            status: number;
            createdTime: string;
            index: number;
            subject: string;
            estimatedTime: number;
            issueId: number;
            issue: string;
          },
        ];
        issue_OtherResourceQuotas: [
          {
            requiredQuantity: string;
            otherResourcesDimId: number;
            otherResource: {
              id: number;
              name: string;
              hourlyRate: number;
              description: string;
              status: number;
              issue_OtherResourceQuotas: string[];
            };
            issuesId: number;
            issue: string;
          },
        ];
        issueLaborQuotas: [
          {
            requiredHours: string;
            skillLevel: number;
            status: number;
            laborId: number;
            laborDim: {
              id: number;
              name: string;
              hourlyRate: number;
              description: string;
              skillSet: number;
              status: number;
              issueLaborQuotas: string[];
            };
            issuesId: number;
            issue: string;
          },
        ];
        issueMachineQuotas: [
          {
            requiredHours: string;
            status: number;
            description: string;
            machineId: number;
            machineryDim: {
              id: number;
              name: string;
              hourlyRate: number;
              description: string;
              skillSet: number;
              status: number;
              issueMachineQuotas: string[];
            };
            issuesId: number;
            issue: string;
          },
        ];
        issueMaterialsQuotas: [
          {
            requiredQuantity: string;
            unitOfMeasure: string;
            status: number;
            materialId: number;
            material: {
              id: number;
              name: string;
              type: number;
              description: string;
              unitOfMeasure: number;
              status: number;
              issueMaterialsQuotas: string[];
            };
            issuesId: number;
            issue: string;
          },
        ];
      };
      fileName: string;
      url: string;
    },
  ];
  tagVersionId: number;
  tagVersionName: string;
  notes: string;
  issueContacts: IssueContactDTO[];
  issueContactDTOs: ContactDTO;
  assignedTo: ContactDTO;
  createdBy: ContactDTO;
  attributes: AttributeDTO[];
}
export interface CreateContactDTO {
  id: number;
  fullname: string;
  phone: string;
  email: string;
  userId: string;
  employeeId: number;
  employeeCode: number;
  titleType: number;
}
export interface CreateIssuePayload {
  parentId: number;
  projectId: number;
  categoryId: number;
  trackerId: number;
  subject: string;
  description: string;
  priority: number;
  status: number;
  type: number;
  estimatedTime: number;
  startDate: string;
  dueDate: string;
  progress: number;
  tagVersionId: number;
  tagVersionName: number;
  notes: string;
  createContacts: CreateContactDTO[];
  assignedTo: CreateContactDTO;
}

export interface UpdateIssuePayload {
  name: string;
  description: string;
  price: number;
}

class IssueController {
  public Get = {
    getIssues: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/api/Issue/project/${projectId}`, options);
    },
    getIssueStatusList: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/issue/Tag/project/${projectId}`, options);
    },
    getIssueProgressList: (projectId: number, options?: RequestOptions) => {
      return HttpClient.get(`${apiUrl}/issue/Tag/project/${projectId}`, options);
    },
  };

  public Post = {
    createIssue: (input: CreateIssuePayload, options?: RequestOptions) => {
      return HttpClient.post(`${apiUrl}/api/Issue`, input, options);
    },
  };

  public Put = {
    updateIssue: (issueId: number, input: UpdateIssuePayload, options?: RequestOptions) => {
      return HttpClient.put(`${apiUrl}/api/Issue/${issueId}`, input, options);
    },
  };

  public Delete = {
    removeIssue: (issueId: number, options?: RequestOptions) => {
      return HttpClient.delete(`${apiUrl}/api/Issue/${issueId}`, options);
    },
  };
}

export const IssueService = new IssueController();
