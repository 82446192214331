import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { defaultPagingParams } from '@/common/define';
import { TeamResponse } from '@/services/TeamService';

interface TeamState {
  teams: TeamResponse[];
  selectedTeam: any | null;
  selectedTeamDetails?: any;
  queryParams: any;
  createUpdateTeamModalTab?: string;
}

const initialState: TeamState = {
  teams: [],
  selectedTeam: null,
  queryParams: defaultPagingParams,
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    setSelectedTeam: (state, action: PayloadAction<any | null>) => {
      state.selectedTeam = action.payload;
    },
    setQueryParams: (state, action) => {
      state.queryParams = action.payload;
    },
    setTeams: (state, action) => {
      state.teams = action.payload;
    },
    getTeamsRequest: (state, action) => {},
    getTeamDetailsRequest: (state, action) => {},
    setSelectedTeamDetails: (state, action) => {
      state.selectedTeamDetails = action.payload;
    },
    createTeamRequest: (state, action) => {},
    updateTeamRequest: (state, action) => {},
    removeTeamRequest: (state, action) => {},
    updateTeamShiftRequest: (state, action) => {},
    createManyTeamMembersRequest: (state, action) => {},
    createMemberRequest: (state, action) => {},
    removeMemberFromTeamRequest: (state, action) => {},
    updateTeamLeadRequest: (state, action) => {},
    setCreateUpdateTeamModalTab: (state, action: PayloadAction<'team_info' | 'team_members' | 'team_shifts'>) => {
      state.createUpdateTeamModalTab = action.payload;
    },
  },
});

export const teamActions = teamSlice.actions;
export const teamReducer = teamSlice.reducer;
