import React from 'react';

import { Col, Row } from 'antd';
import { useNavigate } from 'react-router-dom';

import { GeneralInfo, NavigateButtons, ProjectMember, TitleSuccessfully } from './components';
import styles from './CreateProjectSucessfully.module.less';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCreateProjectInformationValue, projectActions } from '@/store/project';


export const CreateProjectSuccessfully = () => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const createProjectInformationValue = useAppSelector(getCreateProjectInformationValue());

  const handleBackToProjectList = () => {
    dispatch(projectActions.setCreateProjectCurrentStep(0));
    dispatch(projectActions.setCreateProjectInformationValue(null));
    dispatch(projectActions.setProjectMemberList([]));
    dispatch(projectActions.setProjectAvatar(''));
    navigate('/projects');
  };

  const handleGotoBiddingPage = () => {
    dispatch(projectActions.setCreateProjectCurrentStep(0));
    dispatch(projectActions.setCreateProjectInformationValue(null));
    dispatch(projectActions.setProjectMemberList([]));
    dispatch(projectActions.setProjectAvatar(''));
    navigate('/projects');
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.bodyContainer}>
        <Row gutter={[30, 24]}>
          <Col span={24} className={styles.bodyTopContainer}>
            {createProjectInformationValue && (
              <TitleSuccessfully createProjectInformationValue={createProjectInformationValue} />
            )}
          </Col>
          <Col span={24} md={24} xl={14}>
            {createProjectInformationValue && (
              <GeneralInfo createProjectInformationValue={createProjectInformationValue} />
            )}
          </Col>
          <Col span={24} md={24} xl={10}>
            <ProjectMember />
          </Col>
        </Row>
        <NavigateButtons handleBackToProjectList={handleBackToProjectList} handleGotoBiddingPage={handleGotoBiddingPage}/>
      </div>
    </div>
  );
};
