import React, { useState } from 'react';

import { CloudUploadOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import type { UploadFile, UploadProps } from 'antd';
import { Modal, Form, Input, DatePicker, Select, Row, Typography, Upload, Button, Space, Tooltip, Col } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { CreateUpdateIssueModalName, FormatDateAPI, NatureOfWorks, WorkingProgress, formatDateDisplay } from '@/common/define';
import { IssuesResponse } from '@/services/IssueService';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIssueProgressList, getIssueStatusList, getSelectedIssue, issueActions } from '@/store/issue';
import { getModalVisible, hideModal } from '@/store/modal';
import { getProjectMembers, getSelectedProject } from '@/store/project';

export const CreateUpdateIssue = () => {
  const { t } = useTranslation('bidding');

  const [form] = Form.useForm();

  const dispatch = useAppDispatch();

  const isModalOpen = useAppSelector(getModalVisible(CreateUpdateIssueModalName));
  const selectedIssue = useAppSelector(getSelectedIssue());
  const selectedProject = useAppSelector(getSelectedProject());
  const projectMembers = useAppSelector(getProjectMembers());
  const issueStatusList = useAppSelector(getIssueStatusList());
  const issueProgressList = useAppSelector(getIssueProgressList());

  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const props: UploadProps = {
    beforeUpload: file => {
      setFileList([...fileList, file]);

      return false;
    },
    fileList,
    showUploadList: false,
  };

  const removefile = (file: UploadFile<any>) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const handleSaveIssue = (values: any) => {
    if (selectedProject && projectMembers) {
      // assignedTo
      const selectedAssignee = projectMembers.results.find(mem => mem.employeeId === values.assignee);
      let assignedTo = undefined;
      if(selectedAssignee) {
        const {id, ...remainingFields} = selectedAssignee;
        assignedTo = {
          ...remainingFields,
          fullname: selectedAssignee?.name ?? '',
          titleType: 1,
        }
      }

      // createContacts
      const selectedSupervisor = projectMembers.results.find(mem => mem.employeeId === values.supervisorId);
      let createContacts = undefined;
      if(selectedSupervisor) {
        const {id, ...remainingFields} = selectedSupervisor;
        createContacts = {
          ...remainingFields,
          fullname: selectedSupervisor?.name ?? '',
          titleType: 2,
        }
      }

      const selectedTag = issueProgressList.find(tag => tag.id === values.tagVersionId);
      
      const inputData = {
        subject: values.subject,
        description: values.description,
        parentId: values.parentId,
        tagVersionId: values.tagVersionId,
        tagVersionName: selectedTag ? selectedTag.name : '',
        type: values.type,
        startDate: values.startDate ? values.startDate.format(FormatDateAPI) : undefined,
        dueDate: values.dueDate ? values.dueDate.format(FormatDateAPI) : undefined,
        status: values.status,
        progress: values.progress,
        notes: values.notes,
        projectId: selectedProject.id,
        createContacts: createContacts ? [{...createContacts}] : [],
        assignedTo,
      }
      if (selectedIssue) {
        dispatch(issueActions.updateIssueRequest({ issueId: selectedIssue.id, issue: { ...selectedIssue, ...inputData }}));
        return;
      }
      dispatch(issueActions.createIssueRequest({ issue: inputData}));
    }
  };

  const confirmRemoveIssue = (issue: IssuesResponse) => {
    Modal.confirm({
      title: t('Notification'),
      content: (
        <div
          dangerouslySetInnerHTML={{
            __html: t('Confirm remove', {
              name: `<strong>"${issue.id}"</strong>`,
            }),
          }}
        />
      ),
      closable: true,
      onOk: close => {
        handleRemoveIssue(issue.id);
        close();
      },
    });
  };

  const handleRemoveIssue = (issueId: number) => {
    if(selectedProject) {
      dispatch(issueActions.removeIssueRequest({ issueId, projectId: selectedProject.id }));
    }
  };

  const handleCancel = () => {
    dispatch(issueActions.setSelectedIssue(undefined));
    dispatch(hideModal({ key: 'CreateUpdateIssueModal' }));
  };

  const handleOk = () => form.submit();

  return (
    <Modal
      title={selectedIssue ? t('Edit') : t('New')}
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText={t('Save')}
      footer={(_, { OkBtn, CancelBtn }) =>
        selectedIssue ? (
          <Row style={{ margin: 0 }} align="stretch">
            <Space style={{ flex: 1 }}>
              <Button key="remove" type="primary" danger onClick={() => confirmRemoveIssue(selectedIssue)}>
                {t('Remove')}
              </Button>
            </Space>
            <Space>
              <CancelBtn />
              <OkBtn />
            </Space>
          </Row>
        ) : (
          <>
            <CancelBtn />
            <OkBtn />
          </>
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          ...selectedIssue,
          startDate: selectedIssue?.startDate ? dayjs(selectedIssue.startDate) : null,
          dueDate: selectedIssue?.dueDate ? dayjs(selectedIssue.dueDate) : null,
          supervisorId: selectedIssue?.issueContacts[0]?.contact ? selectedIssue.issueContacts[0].contact.employeeId: null,
          assignee: selectedIssue?.assignedTo ? selectedIssue.assignedTo.employeeId : null,
        }}
        onFinish={handleSaveIssue}
        autoComplete="off"
      >
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Form.Item
              label={t('Subject')}
              name="subject"
              rules={[{ required: true, message: t('Please input name!') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Description')}
              name="description"
              rules={[{ required: true, message: t('Please input description!') }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item label={t('Parent')} name="parentId">
              <Input />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Supervisor')}
              name="supervisorId"
              rules={[{ required: true, message: t('Please input supervisor!') }]}
            >
              <Select allowClear options={projectMembers?.results.map(x => ({ label: x.name, value: x.employeeId }))} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={t('Assignee')}
              name="assignee"
              rules={[{ required: true, message: t('Please input assignee!') }]}
            >
              <Select
                allowClear
                options={projectMembers?.results.map(x => ({ label: x.name, value: x.employeeId }))}
                optionFilterProp="label"
              />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Phase')}
              name="tagVersionId"
              rules={[{ required: true, message: t('Please input phase!') }]}
            >
              <Select options={issueProgressList.map(status => ({ label: status.name, value: status.id }))} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Nature of work')}
              name="type"
              rules={[{ required: true, message: t('Please input Nature of work!') }]}
            >
              <Select options={NatureOfWorks()} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Start date')}
              name="startDate"
              rules={[{ required: true, message: t('Please input start date!') }]}
            >
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Due date')}
              name="dueDate"
              rules={[{ required: true, message: t('Please input due date!') }]}
            >
              <DatePicker style={{ width: '100%' }} format={formatDateDisplay} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Status')}
              name="status"
              rules={[{ required: true, message: t('Please input status!') }]}
            >
              <Select options={issueStatusList.map(status => ({ label: status.name, value: status.id }))} />
            </Form.Item>
          </Col>
          <Col span={24} md={12}>
            <Form.Item
              label={t('Progress')}
              name="progress"
              rules={[{ required: true, message: t('Please input progress!') }]}
            >
              <Select options={WorkingProgress} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={t('Notes')} name="notes">
              <Input.TextArea rows={2} />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item style={{ marginBottom: 0 }}>
          <Row align="stretch" style={{ margin: 0 }}>
            <Typography.Text style={{ flex: 1 }} className="ant-form-item-label">
              {t('Attachments')}
            </Typography.Text>
            <Upload {...props}>
              <Button type="link" icon={<CloudUploadOutlined />} style={{ padding: 0 }}>
                {t('Click to Upload')}
              </Button>
            </Upload>
          </Row>
          {fileList.length > 0 && (
            <div style={{ border: '1px solid #D9D9D9', padding: 10, borderRadius: 8 }}>
              {fileList.map(f => (
                <Row key={f.uid} style={{ margin: 0 }} className="app-upload-list-item">
                  <Space style={{ flex: 1 }}>
                    <PaperClipOutlined />
                    <span>{f.name}</span>
                  </Space>
                  <Tooltip title={t('Remove file')}>
                    <DeleteOutlined
                      role="button"
                      style={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => removefile(f)}
                    />
                  </Tooltip>
                </Row>
              ))}
            </div>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
};
