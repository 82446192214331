import React, { useEffect, useRef } from 'react';

import { Typography } from 'antd';
import { gantt } from 'dhtmlx-gantt';

import { useAppSelector } from '@/store/hooks';
import { getSelectedProject } from '@/store/project';

export const PublicPage = () => {
  const selectedProject = useAppSelector(getSelectedProject());
  const ganttContainer = useRef<HTMLDivElement>(null);

  const textEditor = { type: "text", map_to: "text" };
  const dateEditor = { type: "date", map_to: "start_date" };
  const durationEditor = {type: "number", map_to: "duration", min:0, max: 1000};

  useEffect(() => {
    if (!ganttContainer.current) {
      return;
    }
    gantt.config.date_format = '%Y-%m-%d %H:%i';
    gantt.config.columns = [
      { name: 'text', label: 'DANH SÁCH CÔNG VIỆC', align: 'left', width: 250, tree: true, resize: true, editor: textEditor },
      { name: 'duration', label: 'KÉO DÀI', align: 'center', width: 80, resize: true, editor: durationEditor },
      { name: 'start_date', label: 'BẮT ĐẦU', align: 'center', width: 80, resize: true, editor: dateEditor },
      { name: 'staffing', label: 'NHÂN SỰ', align: 'center', width: 80, resize: true },
      { name: 'end_date', label: 'KẾT THÚC', align: 'center', width: 80, resize: true },
      { name: 'add', label: '', width: 44 }
    ];
    gantt.config.lightbox.sections=[
      {name:"description", height:70, map_to:"text", type:"textarea", focus:true},
      {name:"staffing", height: 22, map_to:"staffing", type:"textarea" },
      {name:"time", map_to:"auto", type:"duration", year_range: 20 }
    ];
    gantt.locale.labels.section_description = "Công việc";
    gantt.locale.labels.section_staffing = "Nhân sự";
    gantt.locale.labels.section_time = "Thời gian";
    gantt.attachEvent("onGanttReady", function(){
      gantt.locale.labels.gantt_save_btn = "Lưu";
      gantt.locale.labels.gantt_cancel_btn = "Hủy bỏ";
      gantt.locale.labels.gantt_delete_btn = "Xóa";
    });
    gantt.plugins({ tooltip: true }); 
    gantt.templates.tooltip_text = function(start,end,task){
      return "<b>Công việc:</b> "+task.text+"<br><b>Bắt đầu:</b>"+task.start_date+"<br><b>Kết thúc:</b>"+end+"<br/><b>Kéo dài:</b> "+task.duration+ (task.progress && "<br><b>Phần trăm:</b>"+task.progress*100+"%");
    };
    gantt.init(ganttContainer.current)
    gantt.parse({
      data: [
        /* prettier-ignore */
        {id: 1, text: "Thi công xây dựng " + selectedProject?.name || 'nhà chị Uyên', start_date: null, end_date: null, duration: null, parent:0, progress: 0, open: true},
        {id: 2, text: "Công tác chuẩn bị", start_date: "2023-08-01 00:00", end_date: null, duration:5, staffing: 'NC 10', parent:1, progress: 1},
        {id: 3, text: "Phần móng", start_date: "2023-08-06 00:00", end_date: null, duration:2, parent:1, progress: 0.5},
        {id: 4, text: "Phần thân", start_date: null, end_date: null, duration: null, parent:1, progress: 0.8, open: true},
        {id: 5, text: "LD CP, sàn tầng 1", start_date: "2023-08-09 00:00", end_date: null, duration:2, parent:4, progress: 0.2},
        {id: 6, text: "LD CP, cốt thép sàn tầng 1", start_date: "2023-08-11 00:00", end_date: null, duration:1, parent:4, progress: 0}
        /* prettier-ignore */
      ],
      links:[
        {id:1, source:2, target:3, type:"0"},
        {id:2, source:3, target:4, type:"0"}
      ]
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography.Title style={{ marginLeft: 20, marginTop: 10 }} level={4}>
        Lập tiến độ ban đầu
      </Typography.Title>
      <div ref={ganttContainer} style={{ width: '100%', height: 'calc(100vh - 120px)' }}></div>
    </>
  );
};
